import React from "react";
import { useNavigate } from "react-router-dom";
import error from "../../image/error.svg";

function FailedPayment({application_id}) {
  const navigate = useNavigate();


  return (
    <div className="flex flex-col h-[362px] gap-y-[40px] sm:justify-between  items-center">
      <div className="flex flex-col items-center gap-y-[24px]">
        <img src={error} alt="" />
        <span className="font-bold text-[20px] sm:text-[24px]  md:text-[24px]">
        附款失敗
        </span>
        <span className="text-[#545454] text-[14px] sm:text-base  md:text-base">付款未完成，請重新選擇付款方式。</span>
        </div>
        <button 
          className="w-[288px] sm:w-[460px]  md:w-[460px] h-[58px] text-white bg-btn01 hover:bg-[#66C0DD] active:bg-[#2F99BA] rounded-[30px]" 
          onClick={() => navigate(`/progress/status/choose`, { state: { applicationId: application_id } })}
        >
          選擇付款方式
        </button>
    </div>
  );
}

export default FailedPayment;
