import React,{useEffect,useState} from "react";
import { useNavigate } from "react-router-dom"; 
import apiBaseURL from "../config";
import axios from "axios";
import one from "../image/one.svg";
import two from "../image/two.svg";
import three from "../image/three.svg";
import four from "../image/four.svg";

function Progress({isModalOpen, closeModal }) {
  const [applications, setApplications] = useState([]);
  const navigate = useNavigate();
  const statusLabels = {
    PENDING: "審核中",
    APPROVED: "已批准",
    PAID :"待填寫取件時間",
    READY_FOR_PICKUP: "待取件",
    PROCESSING: "辦理中",
    WAITING_FOR_DELIVERY_INFO:"等待填寫送件地址",
    READY_FOR_DELIVERY: "待送件",
    COMPLETED: "已完成",
    WAITING_FOR_REMITTANCE_CONFIRMATION:"已匯款完成-等待核對",
    WAITING_FOR_ADDITIONAL_DOCUMENT:"要補件"
  };

  useEffect(() => {
    const token = localStorage.getItem('access_token');
  
    axios
      .get(`${apiBaseURL}/v1/traveler/applications`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      })
      .then((response) => {
        setApplications(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching applications:", error);
      });
  }, []);


  const renderSteps = (steps) => {
    return steps.map((step, index) => (
      <div className="flex items-center text-[14px] sm:text-[12px]" key={step}>
        <div className="w-6 h-6 sm:w-8 sm:h-8 flex items-center justify-center rounded-full border border-[#A3A3A3] border-2">
          {step}
        </div>
        {index < steps.length - 1 && <div className="w-[29px] sm:w-20 h-1 bg-[#A3A3A3]"></div>}
      </div>
    ));
  };



  const handleApplicationClick = (application_id) => {
    localStorage.setItem("application_id", application_id);
    navigate('/progress/status', { state: { resetOfflinePayment: true } });
  };

  const renderApplications = () => {
    const sortedApplications = [...applications].sort((a, b) => b.application_id - a.application_id);
  
    return sortedApplications.map((app) => {
      const isProcessing = app.application_status === "PROCESSING";
  
      return (
        <div
          className={`bg-white rounded-xl min-w-[288px] max-w-[954px] w-full  ${isProcessing ? 'cursor-not-allowed ' : 'cursor-pointer'}`}
          key={app.application_id}
          onClick={!isProcessing ? () => handleApplicationClick(app.application_id) : null}
        >
          <div className="h-[40px] px-6 flex items-center bg-btn01 text-white font-bold rounded-t-xl">
            <span>申請編號: {app.application_id}</span>
          </div>
          <div className="sm:p-6 p-4 w-full flex flex-col gap-y-[10px] sm:gap-y-[24px] border-x-2 border-b-2 rounded-b-xl border-btn01 hover:bg-[rgba(102,192,221,0.1)]">
            <div className="w-full flex flex-col gap-y-[8px]">
              <span className="font-bold  text-base sm:text-[18px]">申請時間: <span className=" text-base sm:text-[18px] font-normal pl-2">{app.application_date}</span></span>
              <span className="font-bold  text-base sm:text-[18px]">申辦人: <span className=" text-base sm:text-[18px font-normal pl-2">{app.name}</span></span>
              <span className="font-bold  text-base sm:text-[18px]">申辦件數: <span className=" text-base sm:text-[18px] font-normal pl-2">{app.quantity}</span></span>
              <span className="font-bold  text-base sm:text-[18px]">總件數: <span className=" text-base sm:text-[18px] font-normal pl-2">{app.quantity}</span></span>
            </div>
            <hr className="border border-[#A3A3A3]" />
            <span className="font-bold text-base flex justify-between sm:text-[18px]">
              申辦進度: <span className="text-base sm:text-[18px] font-normal pl-2">{statusLabels[app.application_status] || app.application_status}</span>
            </span>
          </div>
        </div>
      );
    });
  };
  
  
  return (
    <div className="flex justify-center px-4 mb-[100px] mt-[88px]">
    {applications.length === 0 ? (
      <div className="mt-[50px]  gap-y-[50px] flex flex-col items-center">
        <div className="flex items-center justify-between">
          {renderSteps(["01", "02", "03", "04", "05", "06"])}
        </div>
        <span className="font-bold text-[20px] sm:text-[24px]">尚未申請任何服務</span>
      </div>
    ) : (
      <div className="flex flex-col items-center gap-y-[24px] sm:gap-y-[40px] w-[960px] mt-[50px]">
        <span className="font-bold text-[24px]">證件申請總覽</span>
        {renderApplications()}
      </div>
    )}
      {/* 說明 */}
      {isModalOpen && (
        <div
          className="bg-black/70 fixed inset-0 flex justify-center items-center z-30"
          onClick={closeModal} // 點擊模態框外部來關閉
        >
          <div
            className="mx-4 gap-y-[16px] px-4 sm:px-[60px] py-[40px] relative bg-white z-10  flex flex-col rounded-[25px]"
            onClick={(e) => e.stopPropagation()} // 阻止點擊事件向上冒泡
          >
            <div className="w-10 h-10 cursor-pointer pt-5 pr-4 sm:pt-4 sm:pr-[14px] md:pr-[29px] absolute right-0 top-0"
             onClick={closeModal}
            >
            <button
              className="cross  w-6 h-6 sm:w-[24px] sm:h-[24px]  "
            ></button>
            </div>
            <div className=" grid gap-y-[16px]">
            <div className="flex ">
              <img src={one} alt="" className="w-[24px] sm:w-[40px]  h-[24px] sm:h-[40px]  mr-2 sm:mr-6 " />
              <div>
                <span className="text-[18px] sm:text-[32px]  font-bold">
                  選擇要辦理<span className="text-orange">護照</span>還是
                  <span className="text-orange">台胞證</span>
                </span>
                <br />
                <span className="text-[16px] sm:text-[20px] leading-[28px]">
                  護照加辦台胞證，幫家人一起申請也OK!
                </span>
              </div>
            </div>
            <div className="flex">
              <img src={two} alt="" className="w-[24px] sm:w-10 h-[24px] sm:h-10 mr-2 sm:mr-6 " />
              <div>
                <span className="text-[18px] sm:text-[32px] font-bold">
                  確認好內容後<span className="text-orange">上傳文件</span>送出申請
                </span>
              </div>
            </div>
            <div className="flex">
              <img src={three} alt="" className="w-[24px] sm:w-10 h-[24px] sm:h-10 mr-2 sm:mr-6 " />
              <div>
                <span className="text-[18px] sm:text-[32px] font-bold">
                  通過審核批准後可至<span className="text-orange">進度查詢</span>前往繳費
                </span>
                <br />
                <span className="text-[16px] sm:text-[20px] leading-[28px]">
                  繳費完成後可以選擇時間跟地點安排人員前往收取相關文件
                </span>
              </div>
            </div>
            <div className="flex">
              <img src={four} alt="" className="w-[24px] sm:w-10  h-[24px] sm:h-10  mr-2 sm:mr-6 " />
              <div>
                <span className="text-[18px] sm:text-[32px] font-bold">等待收件就完成囉 !</span>
                <br />
                <span className="text-[16px] sm:text-[20px] leading-[28px]">
                  證件辦理完成後填寫送件地址，可把證件送至府上 !
                </span>
              </div>
            </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Progress;
