import React from "react";
import { useNavigate,  } from "react-router-dom";

function Approved( { application_id }) {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col h-[362px] gap-y-[40px] sm:justify-between  items-center">
      <div className="flex flex-col items-center gap-y-[24px]">
      <span className="font-bold text-[20px] sm:text-[24px]  md:text-[24px]">
      已批准狀態
      </span>
      <span className="text-[#545454] text-[14px] sm:text-base  md:text-base">此申請單為已批准狀態，請選擇付款方式。</span>
      </div>
      <button 
        className="w-[288px] sm:w-[460px]  md:w-[460px] font-bold h-[58px] text-white bg-btn01 hover:bg-[#66C0DD] active:bg-[#2F99BA] rounded-[30px]" 
        onClick={() => navigate(`/progress/status/choose`, { state: { applicationId: application_id } })}
      >
        選擇付款方式
      </button>
    </div>
  );
}

export default Approved;
