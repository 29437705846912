import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import logoutWhite from "../image/logout_white.svg";
import questionWhite from "../image/question_white.svg";
import question2White from "../image/question2_white.svg";
import back from "../image/back.svg";
import hamburger from "../image/hamburger.svg";
import close from "../image/close.svg";

const NavBar = ({ onOpenModal, onLogout,isOfflinePayment  }) => { 
    const navigate = useNavigate();
    const location = useLocation(); 
    const backstage = false; 
    const isProgressPage = location.pathname.startsWith('/progress');
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const handleLogout = () => {
        onLogout(); 
        navigate('/'); 
    };

    const handleBack = () => {
        if (location.pathname === '/progress/status/done') {
            navigate('/progress');
        } else if (location.pathname === '/progress/status') {
            if (isOfflinePayment) {
                navigate(-1); // 如果是線下付款，返回上一頁
            } else {
                navigate('/progress'); // 否則跳轉到進度頁面
            }
        } else if (location.pathname === '/progress') {
            navigate('/custom'); // 保留原有的跳轉到 /custom 的邏輯
        } else {
            navigate(-1);
        }
    };
    
    

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const closeSidebar = () => {
        setIsSidebarOpen(false);
    };

    return (
        <div>
            {!isProgressPage ? (
                <div className={`flex w-screen h-[57px] sm:h-[82px] md:h-[88px] z-30 fixed top-0 bg-white items-center justify-between py-4 sm:py-6 px-4 sm:px-8 md:px-16 shadow-md`}>
                    <div className=" sm:w-[200px] lg:w-[330px] text-[18px] sm:text-[24px] md:text-[32px]   font-black font-roboto font-medium">Who's Out</div>
                    <p className=" absolute left-1/2 transform -translate-x-1/2 text-[14px] sm:text-[24px] font-bold ">證件申辦</p>
                    <div className="flex items-center cursor-pointer ">
                        <img src={hamburger} alt="menu" className="w-[18px] h-[18px] sm:w-[24px] sm:h-[24px]" onClick={toggleSidebar} />
                    </div>
                </div>
            ) : (
                <div className={`flex  fixed top-0 w-screen h-[52px] sm:h-[82px] md:h-[88px] z-30  bg-white items-center justify-between py-4 sm:py-6 px-4 sm:px-8 md:px-16 shadow-md`}>
                    <div className='flex items-center w-[200px] md:w-[220px]  cursor-pointer' onClick={handleBack}>
                        <img src={back} alt="" className='w-[20px] sm:w-[40px] h-[20px] sm:h-[40px]' />
                        <span className="text-[12px] sm:text-[20px] hidden sm:block">返回</span>
                    </div>
                    <p className="text-[14px] absolute left-1/2 transform -translate-x-1/2 sm:text-[24px] font-bold">進度查詢</p>
                    <div className="flex justify-end w-[52px] items-center cursor-pointer ">
                        <img src={hamburger} alt="menu" className="w-[18px] sm:w-[24px] h-[18px] sm:h-[24px]" onClick={toggleSidebar} />
                    </div>
                </div>
            )}

            {/* 點擊側邊欄外部背景 */}
            {isSidebarOpen && (
                <div className="fixed inset-0 z-40" onClick={closeSidebar}>
                    <div className="absolute top-0 right-0 w-[122px] sm:w-[186px] md:w-[252px] h-full bg-black bg-opacity-75 text-white z-50 shadow-lg pt-[20px] sm:pt-[30px] px-4 sm:px-[31px] md:px-[64px] flex flex-col gap-y-[24px] sm:gap-y-[32px]" 
                    onClick={(e) => e.stopPropagation()}
                    >
                        <div className="flex justify-end">
                            <img src={close} alt="close" className="w-[18px] h-[18px] sm:w-6 sm:h-6 cursor-pointer" onClick={toggleSidebar} />
                        </div>
                        <div className="flex flex-col items-start gap-y-6 sm:gap-y-8">
                            <div className={`flex items-center ${isProgressPage ? 'justify-center' : 'justify-start'} w-full cursor-pointer`} onClick={() => { onOpenModal(onOpenModal); setIsSidebarOpen(false); }}>
                                <img alt='' src={question2White} className="w-[18px] h-[18px] sm:w-10 sm:h-10 " />
                                <p className='text-base sm:text-[20px] font-bold pl-1'>說明</p>
                            </div>
                            {!backstage && !isProgressPage && (
                                <Link to="/progress">
                                    <div className="h-[18px] sm:h-10 flex items-center cursor-pointer" onClick={() => {setIsSidebarOpen(false); }}>
                                        <img className="h-[18px] sm:h-10" alt="search" src={questionWhite} />
                                        <p className="text-base sm:text-[20px] font-bold">進度查詢</p>
                                    </div>
                                </Link>
                            )}
                            <div className={`flex items-center w-full ${isProgressPage ? 'justify-center' : 'justify-start'} cursor-pointer`} onClick={handleLogout}>
                                <img alt='logout' src={logoutWhite} className="h-[18px] w-[18px] sm:w-10 h-[18px] sm:h-10" />
                                <p className='text-base sm:text-[20px] font-bold pl-1'>登出</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default NavBar;

