import React, { useEffect, useState } from "react";
import apiBaseURL from "../../config";

function Completed({application_id}) {
  const [reportData, setReportData] = useState(null);

  const translateProcessType = (type) => {
    const translationMap = {
      REGULAR: "一般件",
      EXPRESS: "速件",
    };
    return translationMap[type] || type;
  };

  const translatePaymentMethod = (method) => {
    const translationMap = {
      ONLINE: "線上支付",
      REMITTANCE: "匯款",
      OFFLINE:"線下支付"
    };
    return translationMap[method] || method;
  };
  

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("access_token");
      const response = await fetch(`${apiBaseURL}/v1/traveler/applications/${application_id}/payment`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setReportData(data?.data);
    };

    fetchData();
  }, [application_id]);

  return (
    <div className="w-full flex flex-col items-center gap-y-[40px] ">
      <div>
        <span className="font-bold text-[24px] mb-[50px]">已完成</span>
      </div>
      <div className="p-6 w-full flex flex-col gap-y-[24px] rounded-xl border shadow-xl">
        <div className="w-full flex flex-col gap-y-[8px]">
          <div className="grid sm:grid-cols-2 grid-rows-2 sm:grid-rows-none gap-y-2">
            <span className="font-bold text-[14px] sm:text-[18px]">申請時間: 
              <span className="text-[14px] sm:text-[18px] font-normal pl-2">{reportData?.application_date}</span>
            </span>
            <span className="font-bold text-[14px] sm:text-[18px]">申辦人: 
              <span className="text-[14px] sm:text-[18px] font-normal pl-2">{reportData?.name}</span>
            </span>
          </div>
          <div className="grid sm:grid-cols-2 grid-rows-2 sm:grid-rows-none gap-y-2">
            <span className="font-bold text-[14px] sm:text-[18px]">付款方式: 
              <span className="text-[14px] sm:text-[18px] font-normal pl-2">{translatePaymentMethod(reportData?.payment_method)}</span>
            </span>
            <span className="font-bold text-[14px] sm:text-[18px]">付款狀態: 
              <span className="text-[14px] sm:text-[18px] font-normal pl-2">已完成</span>
            </span>
          </div>
          {reportData?.payment_details?.map((detail,index) => (
            <div key={detail.payment_detail_id} className="flex flex-col gap-y-2">
             {index !== 0 && <hr className="border-[#A3A3A3]" />}
              <span className="font-bold text-[14px] sm:text-[18px]">申辦證件: 
                <span className="text-[14px] sm:text-[18px] font-normal pl-2">{detail.name}</span>
              </span>
              <div className="flex justify-between w-full">
                <span className="font-bold text-[14px] sm:text-[18px]">申辦類別: 
                  <span className="text-[14px] sm:text-[18px] font-normal pl-2">{translateProcessType(detail.process_type)}</span>
                </span>
                <span className="text-[14px] sm:text-[18px] font-normal">${detail.total_price}</span>
              </div>
            </div>
          ))}
        </div>
        <hr className=" border-[#A3A3A3]" />
        <div className="flex justify-between w-full">
          <span className=" font-bold text-[14px] sm:text-[18px]">總計: </span>
          <span className="text-[#EE1D52] text-[14px] sm:text-[18px] font-normal">${reportData?.amount} </span>
        </div>
      </div>
      {reportData?.remittance_date && reportData?.account_last_digits && (
        <div className="bg-white w-full h-[96px] sm:h-[73px] grid grid-rows-2 sm:grid-cols-2 gap-y-2 p-[24px] border rounded-xl shadow-xl">
          <div className="flex">
            <div className="font-bold text-[14px] sm:text-[18px]">匯款日期：</div>
            <div className="ml-2 text-[14px] sm:text-base">{reportData?.remittance_date}</div>
          </div>
          <div className="flex">
            <div className="font-bold text-[14px] sm:text-[18px]">帳號末5碼：</div>
            <div className="ml-2 text-[14px] sm:text-base">{reportData?.account_last_digits}</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Completed;
