import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import one from "../image/one.svg";
import two from "../image/two.svg";
import three from "../image/three.svg";
import four from "../image/four.svg";
import PassportFormData from "../components/passport/PassportFormData";
import CompatriotFormData from "../components/compation/CompatriotFormData";
import apiBaseURL from "../config";
import axios from "axios";
import { Incomplete, SuccessHint } from "../components/Hint";
import Loading from "../components/Loading";

function Custom({ isModalOpen, closeModal }) {
  const [selectedOption, setSelectedOption] = useState('');
  const [formData, setFormData] = useState([]);
  const [requestId, setRequestId] = useState(null);
  const [hintVisible, setHintVisible] = useState(false);
  const [successVisible, setSuccessVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate()

  const handleOnChange = async (e) => {
    setSelectedOption(e.target.value);
    setFormData([]);

    // 檢查 requestId 是否已經存在
    if (!requestId) {
      try {
        const accessToken = localStorage.getItem('access_token');
        const response = await axios.post(`${apiBaseURL}/v1/traveler/applications/gen_id`, {}, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });

        // 從回應中提取 request_id 並存儲在狀態中
        setRequestId(response.data.data.request_id);
      } catch (error) {
        console.error('Error fetching request_id:', error);
      }
    }

    setFormData([]);
  };

  const handleSubmit = async () => {
    let hasEmptyField = false;

    const passportApplications = [];
    const compatriotApplications = [];
    const files = new FormData(); // 使用 FormData 來存儲文件資料

    formData.forEach((form, index) => {
      if (form.type === 'passport') {
        // 檢查護照的表單資料是否完整
        if (!form.applicantName) {
          console.log("旅客姓名未填寫");
          hasEmptyField = true;
        }
        if (!form.formDate) {
          console.log("填表日期未填寫");
          hasEmptyField = true;
        }
        if (!form.idNumber) {
          console.log("身分證字號未填寫");
          hasEmptyField = true;
        }
        if (!form.birthDateInput) {
          console.log("出身日期未填寫");
          hasEmptyField = true;
        }
        if (!form.applicantPhone) {
          console.log("連絡電話未填寫");
          hasEmptyField = true;
        }
        if (form.age === null || form.age === undefined) {
          console.log("年齡未正確計算");
          hasEmptyField = true;
        }
        if (!form.delivery_option) {
          console.log("送件類別未選擇");
          hasEmptyField = true;
        }
        if (!form.selectFirst) {
          console.log("申請護照類別未選擇");
          hasEmptyField = true;
        }

        // 檢查外文姓氏和外文名字的特定情況
        if (form.selectFirst === "first" || (form.selectFirst === "change" && form.changeEnglish === "change")) {
          if (!form.foreignLastName) {
            console.log("外文姓氏未填寫");
            hasEmptyField = true;
          }
          if (!form.foreignFirstName) {
            console.log("外文名字未填寫");
            hasEmptyField = true;
          }
        }

        const checkedCount = Object.values(form.checkedStates).filter(value => value).length;
        if (form.files.length < checkedCount) {
          console.log("檔案數量不足");
          hasEmptyField = true;
        }

        // 如果所有檢查都通過，構建 passport 應用資料
        if (!hasEmptyField) {
          const passportData = {
            process_type: form.delivery_option,
            application_category: form.applicationCategory,
            applicant_age: form.age,
            need_ID: !!form.checkedStates.need_ID,
            foreign_first_name: form.foreignFirstName || null, 
            need_lost_report: !!form.checkedStates.need_police_report,
            need_household_registry: !!form.checkedStates.need_household_registry,
            applicant_name: form.applicantName,
            foreign_last_name: form.foreignLastName || null, 
            applicant_birth_date: form.birthDate,
            need_legal_representative: !!form.checkedStates.need_legal_representative,
            ID_number: form.idNumber,
            need_alias_proof_document: !!form.checkedStates.need_old_compatriot,
            alias: form.alias || null, 
            need_resident_certificate: !!form.checkedStates.need_resident_certificate,
            applicant_phone: form.applicantPhone,
            need_old_passport: !!form.checkedStates.need_old_passport,
            need_other_document: !!form.checkedStates.need_other_document,
            need_two_inch_photo: !!form.checkedStates.need_two_inch_photo,
            application_date: form.formDate,
            is_alias_same_as_old_passport: form.is_alias_same_as_old_passport, 
            is_delete_alias: form.is_delete_alias, 
            add_alias: form.add_alias, 
            is_foreign_name_same_as_old_passport: form.is_foreign_name_same_as_old_passport, 
            is_update_foreign_name: form.is_update_foreign_name, 
            update_alias: form.update_alias, 
          };

          passportApplications.push(passportData);
          form.files.forEach((file) => {
            files.append('files', file.compressedFile, file.customName);
          });                
        }
      } else if (form.type === 'compatriot') {
        // 檢查台胞證的表單資料是否完整
        if (!form.applicantName) {
          console.log("旅客姓名未填寫");
          hasEmptyField = true;
        }
        if (!form.idNumber) {
          console.log("身分證字號未填寫");
          hasEmptyField = true;
        }
        if (!form.birthDateInput) {
          console.log("出身日期未填寫");
          hasEmptyField = true;
        }
        if (!form.applicantPhone) {
          console.log("連絡電話未填寫");
          hasEmptyField = true;
        }
        if (form.age === null || form.age === undefined) {
          console.log("年齡未正確計算");
          hasEmptyField = true;
        }

        const checkedCount = form.checkedStates ? Object.values(form.checkedStates).filter(value => value).length : 0;
        if (form.files && form.files.length < checkedCount) {
          console.log("檔案數量不足");
          hasEmptyField = true;
        }

        // 如果所有檢查都通過，構建 compatriot 應用資料
        if (!hasEmptyField) {
          const compatriotData = {
            process_type: form.delivery_option,
            application_category: form.selectFirst, 
            applicant_age: form.age,
            need_ID: !!form.checkedStates.need_ID,
            need_household_registry: !!form.checkedStates.need_household_registry,
            applicant_name: form.applicantName,
            applicant_birth_date: form.birthDate,
            need_police_report: !!form.checkedStates.need_police_report,
            need_legal_representative: !!form.checkedStates.need_legal_representative,
            ID_number: form.idNumber,
            need_resident_certificate: !!form.checkedStates.need_resident_certificate,
            applicant_phone: form.applicantPhone,
            need_other_document: !!form.checkedStates.need_other_document,
            need_two_inch_photo: !!form.checkedStates.need_two_inch_photo,
            need_passport: !!form.checkedStates.need_passport,
            application_date: form.formDate,
            need_old_compatriot: !!form.checkedStates.need_old_compatriot,
          };

          compatriotApplications.push(compatriotData);
          form.files.forEach((file) => {
            files.append('files', file.compressedFile, file.customName);
          });          
        }
      }
    });

    if (hasEmptyField) {
      setHintVisible(true);
      return;
    }

    // 將應用資料與文件一同提交到 API
    try {
      const accessToken = localStorage.getItem('access_token');
      files.append('applications', JSON.stringify({
        compatriot_applications: compatriotApplications,
        passport_applications: passportApplications,
        request_id:requestId
      }));

      setIsLoading(true);  
    console.log('提交資料: ', {
      compatriot_applications: compatriotApplications,
      passport_applications: passportApplications,
      request_id: requestId
    });

      const response = await axios.post(`${apiBaseURL}/v1/traveler/applications`, files, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        setSuccessVisible(true);
        setTimeout(() => {
          setSuccessVisible(false);
          navigate('/progress')
        }, 1500);
      }
    } catch (error) {
      if (error.response) {
        console.error('Error Status:', error.response.status);
        console.error('Error Response:', error.response.data);
      } else if (error.request) {
        console.error('Error Request:', error.request);
      } else {
        console.error('Error Message:', error.message);
      }
    } finally{
      setIsLoading(false)
    }
  };

  return (
    <div className="w-screen h-full flex flex-col items-center mt-[88px] px-4 sm:px-8 pt-[28px] sm:pt-[40px] md:pt-[72px]">
      <div className="bg-white px-4 min-w-[288px] max-w-[954px] w-full py-6 grid gap-[24px] shadow-md rounded-xl mb-[40px]">
        <span className="font-bold text-[16px] sm:text-[24px]">請選擇您要申請的證件 ？</span>
        <div className="grid grid-rows-2 gap-[16px]">
          <label className="text-[14px] sm:text-[20px] flex items-center">
            <input
              type="radio"
              value="護照"
              checked={selectedOption === '護照'}
              className="mr-2  w-[18px] sm:w-[24px] h-[18px] sm:h-[24px]"
              onChange={handleOnChange}
              style={{ accentColor: '#E26925' }}
            />
            護照
          </label>
          <label className="text-[14px] sm:text-[20px] flex items-center">
            <input
              type="radio"
              value="台胞證"
              checked={selectedOption === '台胞證'}
              className="mr-2 w-[18px] sm:w-[24px] h-[18px] sm:h-[24px]"
              onChange={handleOnChange}
              style={{ accentColor: '#E26925' }}
            />
            台胞證
          </label>
        </div>
      </div>
      {selectedOption === '護照' && <PassportFormData onFormDataChange={setFormData} />}
      {selectedOption === '台胞證' && <CompatriotFormData onFormDataChange={setFormData} />}
      {selectedOption && (
        <button className="text-white bg-btn01  hover:bg-[#66C0DD] active:bg-[#2F99BA] rounded-xl  w-[288px] max-w-[954px] sm:w-full mb-[76px] h-[57px] text-base font-bold"
          onClick={handleSubmit}
        >
          提交
        </button>
      )}
      {isLoading && <Loading/>}
      {/* 說明 */}
      {isModalOpen && (
        <div
          className="bg-black/70 fixed inset-0 flex justify-center items-center z-30"
          onClick={closeModal} // 點擊模態框外部來關閉
        >
          <div
            className="mx-4 gap-y-[16px] px-4 sm:px-[60px] py-[40px] relative bg-white z-10  flex flex-col rounded-[25px]"
            onClick={(e) => e.stopPropagation()} // 阻止點擊事件向上冒泡
          >
            <div className="w-10 h-10 cursor-pointer pt-5 pr-4 sm:pt-4 sm:pr-[14px] md:pr-[29px] absolute right-0 top-0"
             onClick={closeModal}
            >
            <button
              className="cross  w-6 h-6 sm:w-[24px] sm:h-[24px]  "
            ></button>
            </div>
            <div className=" grid gap-y-[16px]">
            <div className="flex ">
              <img src={one} alt="" className="w-[24px] sm:w-[40px]  h-[24px] sm:h-[40px]  mr-2 sm:mr-6 " />
              <div>
                <span className="text-[18px] sm:text-[32px]  font-bold">
                  選擇要辦理<span className="text-orange">護照</span>還是
                  <span className="text-orange">台胞證</span>
                </span>
                <br />
                <span className="text-[16px] sm:text-[20px] leading-[28px]">
                  護照加辦台胞證，幫家人一起申請也OK!
                </span>
              </div>
            </div>
            <div className="flex">
              <img src={two} alt="" className="w-[24px] sm:w-10 h-[24px] sm:h-10 mr-2 sm:mr-6 " />
              <div>
                <span className="text-[18px] sm:text-[32px] font-bold">
                  確認好內容後<span className="text-orange">上傳文件</span>送出申請
                </span>
              </div>
            </div>
            <div className="flex">
              <img src={three} alt="" className="w-[24px] sm:w-10 h-[24px] sm:h-10 mr-2 sm:mr-6 " />
              <div>
                <span className="text-[18px] sm:text-[32px] font-bold">
                  通過審核批准後可至<span className="text-orange">進度查詢</span>前往繳費
                </span>
                <br />
                <span className="text-[16px] sm:text-[20px] leading-[28px]">
                  繳費完成後可以選擇時間跟地點安排人員前往收取相關文件
                </span>
              </div>
            </div>
            <div className="flex">
              <img src={four} alt="" className="w-[24px] sm:w-10  h-[24px] sm:h-10  mr-2 sm:mr-6 " />
              <div>
                <span className="text-[18px] sm:text-[32px] font-bold">等待收件就完成囉 !</span>
                <br />
                <span className="text-[16px] sm:text-[20px] leading-[28px]">
                  證件辦理完成後填寫送件地址，可把證件送至府上 !
                </span>
              </div>
            </div>
            </div>
          </div>
        </div>
      )}
      {hintVisible && (
        <Incomplete onClose={() => setHintVisible(false)} />
      )}
      {successVisible && (
        <SuccessHint />
      )}
    </div>
  );
}

export default Custom;
