import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLogin } from "../backend/hook/useApi";
import eye from "../image/eye.svg";
import eyeclose from "../image/closeeye.svg";

function ManagersLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const login = useLogin();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setErrorMessage("");
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setErrorMessage("");
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async () => {
    if (!email || !password) {
      setErrorMessage("請輸入帳號和密碼。");
      return;
    }

    login.mutate(
      { username: email, password },
      {
        onSuccess: (data) => {
          localStorage.setItem("login_time", Date.now().toString());
          navigate("/datamanagement");
        },
        onError: (error) => {
          console.error("登入失敗:", error);
          setErrorMessage("帳號或密碼錯誤，請輸入正確資料。");
        },
      }
    );
  };

  return (
    <div className="grid gap-y-[24px] px-4 xsm:px-[33px] pt-10 xsm:pt-[50px] xsm:h-[540px] relative">
      <p className="text-xl xsm:text-[28px] font-semibold xsm:h-[67px] text-btn01">
        登入
      </p>
      <div className="xsm:h-[107px]">
        <p className="text-base xsm:text-lg mb-1 xsm:h-[27px]">帳號</p>
        <input
          className={`w-full h-[52px] pl-5 checkout-name rounded-xl border-[1px] ${errorMessage ? "border-[#EE1D52]" : "border-[#dedede]"} text-base focus:border-[1px] focus:border-btn01 focus:outline-none focus:ring-4 focus:ring-[rgba(77,168,197,0.2)] text-main-gray`}
          placeholder="mail@example.com"
          type="text"
          name="email"
          value={email}
          onChange={handleEmailChange}
        />
      </div>
      <div className="relative xsm:h-[107px] mt-6 xsm:mt-0">
        <p className="text-base xsm:text-lg mb-1 xsm:h-[27px]">密碼</p>
        <input
          className={`w-full h-[52px] pl-5 checkout-name rounded-xl border-[1px] ${errorMessage ? "border-[#EE1D52]" : "border-[#dedede]"} text-base focus:border-[1px] focus:border-btn01 focus:outline-none focus:ring-4 focus:ring-[rgba(77,168,197,0.2)] text-main-gray`}
          placeholder="請輸入密碼"
          type={showPassword ? "text" : "password"}
          name="password"
          value={password}
          onChange={handlePasswordChange}
        />
        <img
          src={showPassword ? eye : eyeclose}
          alt={showPassword ? "隱藏密碼" : "顯示密碼"}
          className="absolute right-[14px] top-[68%] xsm:top-[55%] w-[24px] h-[24px] transform -translate-y-1/2 cursor-pointer"
          onClick={togglePasswordVisibility}
        />
        {errorMessage && (
          <p className="text-red-500 text-sm mt-2 absolute ">{errorMessage}</p>
        )}
      </div>
      <button
        className={`rounded-full h-[56px] mt-6 mb-[72px] bg-btn01 hover:bg-[#66C0DD] active:bg-[#2F99BA] text-white w-full text-lg sm:text-lg font-bold`}
        onClick={handleLogin}
        disabled={login.isPending}
      >
        {login.isPending ? "登入中..." : "下一步"}
      </button>
    </div>
  );
}

export default ManagersLogin;
