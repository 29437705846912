import React, { useState, useEffect } from "react";
import first from "../../image/first.svg";
import second from "../../image/second.svg";
import datepicker from "../../image/datepicker.svg";
import Dropdown from "./Dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import zhTW from "date-fns/locale/zh-TW";
import apiBaseURL from "../../config";
import axios from "axios";
import Loading from "../Loading";

function Processing({ setApplicationStatus,application_id }) {
  const [selectedCounty, setSelectedCounty] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [cityOptions, setCityOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [deliveryAddress, setDeliveryAddress] = useState(""); 
  const [deliveryPhone, setDeliveryPhone] = useState(""); 
  const [selectedButton, setSelectedButton] = useState(null);
  const [isLoading,setIsLoading] = useState(false)

  const timeOptions = ["08:00-12:00", "12:00-16:00", "16:00-20:00"];

  useEffect(() => {
    const fetchCities = async () => {
      const token = localStorage.getItem("access_token"); 
      try {
        const response = await axios.get(`${apiBaseURL}/v1/cities`, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        });
        const cities = response.data.data.map((city) => ({
          id: city.city_id,
          name: city.city_name,
        }));
        setCityOptions(cities);
      } catch (error) {
        console.error("Failed to fetch cities", error);
      }
    };

    fetchCities();
  }, []);

  const fetchDistricts = async (city_id) => {
    const token = localStorage.getItem("access_token");
    try {
      const response = await axios.get(
        `${apiBaseURL}/v1/cities/${city_id}/districts`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      );
      const districts = response.data.data.map((district) => ({
        id: district.district_id,
        name: district.district_name,
      }));
      setDistrictOptions(districts);
    } catch (error) {
      console.error("Failed to fetch districts", error);
    }
  };

  const GetLastAddress = async(application_id) => {
    const token = localStorage.getItem("access_token");
    try {
      const response = await axios.get(
        `${apiBaseURL}/v1/traveler/applications/${application_id}/pickup_info`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      );
      const { city, district, pickup_address } = response.data.data;
      setSelectedCounty(city);
      setSelectedDistrict(district);
      setDeliveryAddress(pickup_address);
    } catch (error) {
      console.error("Failed to fetch last address", error);
    }
  };

  const handleSubmit = async () => {
    // 檢查所有欄位是否都有填寫
    if (
      !selectedCounty || 
      !selectedDistrict || 
      !deliveryAddress || 
      !selectedDate || 
      !deliveryPhone || 
      !selectedTime
    ) {
      alert("請完整填寫所有資料");
      return;
    }
  
    const token = localStorage.getItem('access_token');
    const payload = {
      city: selectedCounty,
      district: selectedDistrict,
      delivery_address: deliveryAddress,
      delivery_date: selectedDate.toLocaleDateString("zh-TW", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }).replace(/\//g, '-'),
      delivery_phone: deliveryPhone,
      delivery_time: selectedTime,
    };
    console.log(payload.delivery_date)
    try {
      setIsLoading(true)
      const response = await axios.post(
        `${apiBaseURL}/v1/traveler/applications/${application_id}/delivery_info`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      );
      if (response.status === 200) {
        console.log("Success:", response.data);
        setApplicationStatus("READY_FOR_DELIVERY"); 
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }finally{
      setIsLoading(false)
    }
    console.log(payload)
  };
  
  return (
    <div className="w-full mb-[100px]">
      <div className="bg-white px-[16px] py-[24px] flex flex-col gap-y-[40px] rounded-xl shadow-xl mb-[22px]">
        <div className="flex flex-col gap-y-[24px]">
          <div className="flex">
            <img src={first} alt="" />
            <span className="font-bold ml-3 text-[20px] md:text-[24px]">送件區域</span>
          </div>
          <div className="flex flex-col gap-y-4 gap-x-[24px] sm:flex-row">
            <button
              className={`w-full sm:w-[220px] h-[40px] text-base text-base md:text-[18px] rounded-xl ${
                selectedButton === "same" ? "bg-orange text-white" : "bg-[#DEDEDE] text-[#545454]"
              }`}
              onClick={() => {
              setSelectedButton("same")
              GetLastAddress(application_id);
              }}
            >
              同上次
            </button>
            <button
              className={`w-full sm:w-[220px] h-[40px] text-base md:text-[18px] rounded-xl ${
                selectedButton === "change" ? "bg-orange text-white" : "bg-[#DEDEDE] text-[#545454]"
              }`}
              onClick={() => {
                setSelectedButton("change")
                setSelectedCounty(null); 
                setSelectedDistrict(null); 
                setDeliveryAddress(null); 
              }}
            >
              改地址
            </button>
          </div>
          {selectedButton === "change" && (
            <>
              <div className="grid gap-y-[24px] sm:grid-cols-2 gap-x-[32px]">
                <Dropdown
                  label="縣市"
                  options={cityOptions.map((city) => city.name)}
                  selectedOption={selectedCounty}
                  onOptionSelect={(county) => {
                    const selectedCity = cityOptions.find(
                      (city) => city.name === county
                    );
                    setSelectedCounty(county);
                    setSelectedDistrict(""); 
                    fetchDistricts(selectedCity.id); 
                  }}
                  placeholder="縣市"
                />
                <Dropdown
                  label="行政區"
                  options={districtOptions.map((district) => district.name)}
                  selectedOption={selectedDistrict}
                  onOptionSelect={setSelectedDistrict}
                  placeholder="行政區"
                />
              </div>
              <div>
                <div className="text-base md:text-[18px] font-bold mb-[16px]">地址</div>
                <input
                  type="text"
                  className="w-full rounded-md bg-[#F7F7F9] text-base md:text-[18px] Input px-[20px] py-[14px] "
                  placeholder="地址"
                  onChange={(e) => setDeliveryAddress(e.target.value)}
                  value={deliveryAddress || ""}
                />
              </div>
            </>
          )}
          <div>
            <div className="text-base md:text-[18px] font-bold mb-[16px]">連絡電話</div>
            <input 
              type="text" 
              className="w-full rounded-md bg-[#F7F7F9] Input text-base md:text-[18px] px-[20px] py-[14px] " 
              placeholder="請輸入電話號碼"
              onChange={(e) => setDeliveryPhone(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-col gap-y-[24px]">
          <div className="flex">
            <img src={second} alt="" />
            <span className="font-bold ml-3 text-[20px] md:text-[24px]">送件時間</span>
          </div>
          <div className="grid gap-y-[24px] sm:grid-cols-2 gap-x-[32px]">
            <div className="flex flex-col">
              <span className="text-base md:text-[18px] font-bold mb-4">日期</span>
              <div className="relative">
                <input
                  type="text"
                  className="w-full rounded-md bg-[#F7F7F9] Input text-base md:text-[18px] px-[20px] py-[14px]"
                  placeholder={new Date().toISOString().split('T')[0].replace(/-/g, '/')} 
                  value={selectedDate ? selectedDate.toLocaleDateString() : ""}
                  readOnly
                  onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
                />
                <div
                  className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
                >
                  <img src={datepicker} alt="" />
                </div>
                {isDatePickerOpen && (
                  <div className="absolute z-50 mt-2 mb-4 w-full flex justify-end right-0 ">
                    <DatePicker
                      selected={selectedDate}
                      onChange={(date) => {
                        setSelectedDate(date);
                        setIsDatePickerOpen(false);
                        console.log(date)
                      }}
                      inline
                      minDate={new Date(new Date().setDate(new Date().getDate() + 2))}
                      filterDate={(date) => date.getDay() !== 0 && date.getDay() !== 6}
                      locale={zhTW}
                      className="react-datepicker"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col">
              <Dropdown
                label="時間"
                options={timeOptions}
                selectedOption={selectedTime}
                onOptionSelect={setSelectedTime}
                placeholder="08:00-12:00"
                icon={datepicker}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <button className="h-[58px] w-[460px] rounded-[28px] bg-btn01 text-white font-bold"
          onClick={handleSubmit}
        >
          提交
        </button>
      </div>
      {isLoading && <Loading/>}
    </div>
  );
}

export default Processing;
