import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { zhTW } from "date-fns/locale"; 
import axios from "axios";
import datepicker from "../../image/datepicker.svg";
import apiBaseURL from "../../config";
import one from "../../image/one.svg";
import two from "../../image/two.svg";
import three from "../../image/three.svg";
import four from "../../image/four.svg";

function ProgressReport({isModalOpen, closeModal}) {
  const [selectedDate, setSelectedDate] = useState(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [reportData, setReportData] = useState(null); // 用來存儲後端返回的資料
  const [accountLastDigits, setAccountLastDigits] = useState(""); // 新增帳號末五碼的狀態
  const navigate = useNavigate();
  const application_id =localStorage.getItem("application_id");

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      axios.get(`${apiBaseURL}/v1/traveler/applications/${application_id}/payment`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setReportData(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    }
  }, [application_id]);


  const translateProcessType = (type) => {
    const translationMap = {
      REGULAR: '一般件',
      EXPRESS: '速件',
    };
    return translationMap[type] || type;
  };

  

  return (
    <div className=" min-w-[288px] max-w-[954px] w-full sm:px-8 px-4 flex flex-col items-center m-auto mt-[144px] gap-y-[40px] mb-[100px]">
      <div className="p-6 w-full flex flex-col gap-y-[24px] rounded-xl border shadow-xl">
        <div className="w-full flex flex-col gap-y-[8px]">
          <span className="font-bold text-[14px] sm:text-[18px]">申請時間: <span className="text-[14px] sm:text-[18px] font-normal pl-2">{reportData?.application_date}</span></span>
          <span className="font-bold text-[14px] sm:text-[18px]">申辦人: <span className="text-[14px] sm:text-[18px] font-normal pl-2">{reportData?.name}</span></span>
          {reportData?.payment_details?.map((detail,index) => (
            <div key={detail.payment_detail_id} className="flex flex-col gap-y-2">
              {index !== 0 && <hr className="border-[#A3A3A3]" />}
              <span className="font-bold text-[14px] sm:text-[18px]">申辦證件: 
                <span className="text-[14px] sm:text-[18px] font-normal pl-2">{detail.name}</span>
              </span>
              <span className="font-bold text-[14px] sm:text-[18px]">總件數: 
                <span className="text-[14px] sm:text-[18px] font-normal pl-2">{detail.quantity}</span>
              </span>
              <div className="flex justify-between w-full">
                <span className="font-bold text-[14px] sm:text-[18px]">申辦類別: 
                  <span className="text-[14px] sm:text-[18px] font-normal pl-2">{translateProcessType(detail.process_type)}</span>
                </span>
                <span className="text-[14px] sm:text-[18px] font-normal">${detail.total_price}</span>
              </div>
            </div>
          ))}
        </div>
        <hr className="border border-[#A3A3A3]" />
        <div className="flex justify-between w-full">
          <span className="font-bold text-[14px] sm:text-[18px]">總計: </span>
          <span className="text-[14px] sm:text-[18px] font-normal text-[#EE1D52]">${reportData?.amount}</span>
        </div>
      </div>
      <div className="grid  sm:grid-cols-2 w-full gap-y-[24px] sm:gap-x-[32px]">
        <div className="flex flex-col">
          <span className="text-base sm:text-[18px] font-bold mb-4">匯款日期</span>
          <div className="relative"  onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}>
            <input
              type="text"
              className="w-full rounded-md bg-white Input px-[20px] py-[14px]"
              placeholder={new Date().toISOString().split('T')[0].replace(/-/g, '/')} 
              value={selectedDate ? selectedDate.toLocaleDateString() : ""}
              readOnly
            />
            <div
              className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
            >
              <img src={datepicker} alt="" />
            </div>
            {isDatePickerOpen && (
              <div className="absolute w-full flex justify-end z-50 mt-2 right-0">
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => {
                    setSelectedDate(date);
                    setIsDatePickerOpen(false);
                  }}
                  inline
                  minDate={new Date()}
                  locale={zhTW}
                  className="react-datepicker"
                />
              </div>
            )}
          </div>
        </div>
        <div>
          <div className="text-base sm:text-[18px] font-bold mb-[16px]">帳號末5碼</div>
          <input
            type="text"
            className="w-full rounded-md bg-white Input px-[20px] py-[14px] "
            placeholder="末5碼"
            value={accountLastDigits} // 使用新的狀態
            onChange={(e) => setAccountLastDigits(e.target.value)} // 設置onChange事件
          />
        </div>
      </div>
      <button className="font-bold text-btn01 text-base sm:text-[18px] bg-white border border-btn01 w-full sm:w-[400px] md:w-[460px] 
        h-[58px] rounded-[30px] hover:bg-[#66C0DD] hover:text-white active:bg-[#2F99BA]"
          onClick={async () => {
            const token = localStorage.getItem("access_token");
          
            if (token && selectedDate && accountLastDigits) {
              const Date = selectedDate.toLocaleDateString("zh-TW", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }).replace(/\//g, '-');
              try {
                const response = await axios.patch(`${apiBaseURL}/v1/traveler/applications/${application_id}/remit`, {
                  remittance_date: Date,
                  account_last_digits: accountLastDigits,
                }, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                  }
                });
              
                if (response.status === 200) {
                  navigate(`/progress/status/done`, {
                    state: {
                      remittance_date: selectedDate.toISOString().split("T")[0],
                      account_last_digits: accountLastDigits,
                      applicationId: application_id  
                    },
                  });
                } else {
                  console.error("Failed to update remittance info:", response.statusText);
                }
              } catch (error) {
                console.error("Error updating remittance info:", error);
              }
            } else {
              console.warn("Please enter all required information.");
            }
          }}
      >
        完成回報
      </button>
      {/* 說明 */}
      {isModalOpen && (
        <div
          className="bg-black/70 fixed inset-0 flex justify-center items-center z-30"
          onClick={closeModal} // 點擊模態框外部來關閉
        >
          <div
            className="mx-4 gap-y-[16px] px-4 sm:px-[60px] py-[40px] relative bg-white z-10  flex flex-col rounded-[25px]"
            onClick={(e) => e.stopPropagation()} // 阻止點擊事件向上冒泡
          >
            <div className="w-10 h-10 cursor-pointer pt-5 pr-4 sm:pt-4 sm:pr-[14px] md:pr-[29px] absolute right-0 top-0"
             onClick={closeModal}
            >
            <button
              className="cross  w-6 h-6 sm:w-[24px] sm:h-[24px]  "
            ></button>
            </div>
            <div className=" grid gap-y-[16px]">
            <div className="flex ">
              <img src={one} alt="" className="w-[24px] sm:w-[40px]  h-[24px] sm:h-[40px]  mr-2 sm:mr-6 " />
              <div>
                <span className="text-[18px] sm:text-[32px]  font-bold">
                  選擇要辦理<span className="text-orange">護照</span>還是
                  <span className="text-orange">台胞證</span>
                </span>
                <br />
                <span className="text-[16px] sm:text-[20px] leading-[28px]">
                  護照加辦台胞證，幫家人一起申請也OK!
                </span>
              </div>
            </div>
            <div className="flex">
              <img src={two} alt="" className="w-[24px] sm:w-10 h-[24px] sm:h-10 mr-2 sm:mr-6 " />
              <div>
                <span className="text-[18px] sm:text-[32px] font-bold">
                  確認好內容後<span className="text-orange">上傳文件</span>送出申請
                </span>
              </div>
            </div>
            <div className="flex">
              <img src={three} alt="" className="w-[24px] sm:w-10 h-[24px] sm:h-10 mr-2 sm:mr-6 " />
              <div>
                <span className="text-[18px] sm:text-[32px] font-bold">
                  通過審核批准後可至<span className="text-orange">進度查詢</span>前往繳費
                </span>
                <br />
                <span className="text-[16px] sm:text-[20px] leading-[28px]">
                  繳費完成後可以選擇時間跟地點安排人員前往收取相關文件
                </span>
              </div>
            </div>
            <div className="flex">
              <img src={four} alt="" className="w-[24px] sm:w-10  h-[24px] sm:h-10  mr-2 sm:mr-6 " />
              <div>
                <span className="text-[18px] sm:text-[32px] font-bold">等待收件就完成囉 !</span>
                <br />
                <span className="text-[16px] sm:text-[20px] leading-[28px]">
                  證件辦理完成後填寫送件地址，可把證件送至府上 !
                </span>
              </div>
            </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProgressReport;
