import React, { useEffect, useState } from "react";
import apiBaseURL from "../../config";
import axios from 'axios';

function WaitingPickup({ isOfflinePayment, application_id,payment_method }) {
  const [qrCode, setQrCode] = useState(null);
  const [reportData, setReportData] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('access_token');
  
    const fetchQrCode = async () => {
      try {
        const qrCodeResponse = await axios.post(
          `${apiBaseURL}/v1/traveler/applications/${application_id}/qr_code`,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          }
        );
  
        if (qrCodeResponse.status === 200) {
          setQrCode(qrCodeResponse.data.data.qr_code);
        } else {
          console.error("Failed to fetch QR code");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
  
    const fetchPaymentInfo = async () => {
      try {
        const paymentResponse = await axios.get(
          `${apiBaseURL}/v1/traveler/applications/${application_id}/payment`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          }
        );
  
        if (paymentResponse.status === 200) {
          setReportData(paymentResponse.data.data);
        } else {
          console.error("Failed to fetch payment info");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
  
    fetchQrCode();
    fetchPaymentInfo();

  }, [application_id, isOfflinePayment, payment_method]);
  

  const translateProcessType = (type) => {
    const translationMap = {
      REGULAR: '一般件',
      EXPRESS: '速件',
    };
    return translationMap[type] || type;
  };

  return (
    <>
      {reportData?.payment_status !== "PAID" ? (
        <div className="w-full flex flex-col gap-y-[50px]">
          <div className="p-6 w-full flex flex-col gap-y-[24px] rounded-xl border shadow-xl">
          {reportData ? (
          <>
            <div className="w-full flex flex-col gap-y-[8px]">
              <span className="font-bold text-[14px] sm:text-base md:text-[18px]">
                申請時間:
                <span className="text-[14px] sm:text-base md:text-[18px] font-normal pl-2">
                  {reportData?.application_date}
                </span>
              </span>
              <span className="font-bold text-[14px] sm:text-base md:text-[18px]">
                申辦人:
                <span className="text-[14px] sm:text-base md:text-[18px] font-normal pl-2">
                  {reportData?.name}
                </span>
              </span>
              {reportData?.payment_details?.map((detail,index) => (
                 <div key={detail.payment_detail_id} className="flex flex-col gap-y-2">
                  {index !== 0 && <hr className="border-[#A3A3A3]" />}
                   <span className="font-bold text-[14px] sm:text-base md:text-[18px]">申辦證件: 
                     <span className="text-[14px] sm:text-base md:text-[18px] font-normal pl-2">{detail.name}</span>
                   </span>
                   <span className="font-bold text-[14px] sm:text-base md:text-[18px]">總件數: 
                     <span className="text-[14px] sm:text-base md:text-[18px] font-normal pl-2">{detail.quantity}</span>
                   </span>
                   <div className="flex justify-between w-full">
                     <span className="font-bold text-[14px] sm:text-base md:text-[18px]">申辦類別: 
                       <span className="text-[14px] sm:text-base md:text-[18px] font-normal pl-2">{translateProcessType(detail.process_type)}</span>
                     </span>
                     <span className="text-[14px] sm:text-base md:text-[18px] font-normal">${detail.total_price}</span>
                   </div>
                 </div>
               ))}
            </div>
            <hr className=" border-[#A3A3A3]" />
            <div className="flex justify-between w-full">
              <span className="font-bold text-[14px] sm:text-base md:text-[18px]">總計: </span>
              <span className="text-[14px] sm:text-base md:text-[18px] font-normal text-[#EE1D52]">
                ${reportData?.amount}
              </span>
            </div>
          </>
        ) : (
          <div>加載中...</div>
        )}
          </div>
          <div className="flex flex-col gap-y-[30px] justify-between items-center">
            <div className="flex flex-col items-center gap-y-[30px]">
              <span className="font-bold text-[20px] sm:text-[24px]">待付款</span>
              <span className="text-[14px] sm:text-base text-center ">當取件人員前往收取證件申辦資料時，可出示此QR code或提供電話號碼，加速取件人員讀取訂單。</span>
            </div>
            <div className="w-[279px] h-[308px] flex justify-center items-center text-white">
              {qrCode ? <img src={qrCode} alt="QR Code" /> : <div>加載中...</div>}
            </div>
            <div className="flex flex-col items-center text-center text-base sm:text-[24px] mb-10">
              <span className="leading-[1.4]">您的案件已送出，將於取件時收款。<br />金額：<span className="text-[#EE1D52]">{reportData ? reportData.amount : '...'}</span>元，訂單編號為：<span className="text-[#EE1D52]">{application_id}</span>，請保留此QR code截圖。</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col h-[362px] justify-between items-center mb-[100px]">
          <div className="flex flex-col items-center sm:gap-y-[50px] gap-y-2">
            <span className="font-bold text-[20px] sm:text-[24px]">等待取件中</span>
            <span className="text-[14px] sm:text-base text-center md:text-[18px] mb-[40px]">當取件人員前往收取證件申辦資料時，可出示此QR code或提供電話號碼，加速取件人員讀取訂單。</span>
          </div>
          <div className="w-[279px] h-[308px] flex justify-center items-center text-white">
            <img src={qrCode} alt="QR Code" />
          </div>
        </div>
      )}
    </>
  );
}

export default WaitingPickup;
