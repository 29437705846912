import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import apiBaseURL from "../../config";
import one from "../../image/one.svg";
import two from "../../image/two.svg";
import three from "../../image/three.svg";
import four from "../../image/four.svg";

function ProgressContinue({isModalOpen, closeModal}) {
  const navigate = useNavigate();
  const application_id =localStorage.getItem("application_id");
  const [reportData, setReportData] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("access_token");

    const fetchPaymentData = async () => {
      try {
        const response = await axios.get(`${apiBaseURL}/v1/traveler/applications/${application_id}/payment`, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
        });
        setReportData(response.data.data);
      } catch (error) {
        console.error("Error fetching payment data:", error);
      }
    };

    fetchPaymentData();
  }, [application_id]);


  const translateProcessType = (type) => {
    const translationMap = {
      REGULAR: '一般件',
      EXPRESS: '速件',
    };
    return translationMap[type] || type;
  };

  

  return (
    <div className=" min-w-[288px] max-w-[954px] w-full sm:px-8 px-4 flex flex-col items-center m-auto mt-[144px] gap-y-[40px] mb-[100px]">
      {reportData ? (
        <>
          <div className="p-6 w-full flex flex-col gap-y-[24px] rounded-xl border shadow-xl">
            <div className="w-full flex flex-col gap-y-[8px]">
              <span className="font-bold  text-[14px] sm:text-[18px]">
                申請時間:
                <span className=" text-[14px] sm:text-[18px] font-normal pl-2">
                  {reportData?.application_date}
                </span>
              </span>
              <span className="font-bold  text-[14px] sm:text-[18px]">
                申辦人:
                <span className=" text-[14px] sm:text-[18px] font-normal pl-2">
                  {reportData?.name}
                </span>
              </span>
              {reportData?.payment_details?.map((detail,index) => (
                <div key={detail.payment_detail_id} className="flex flex-col gap-y-2">
                  {index !== 0 && <hr className="border-[#A3A3A3]" />}
                  <span className="font-bold text-[14px] sm:text-[18px]">申辦證件: 
                    <span className="text-[14px] sm:text-[18px] font-normal pl-2">{detail.name}</span>
                  </span>
                  <span className="font-bold text-[14px] sm:text-[18px]">總件數: 
                    <span className="text-[14px] sm:text-[18px] font-normal pl-2">{detail.quantity}</span>
                  </span>
                  <div className="flex justify-between w-full">
                    <span className="font-bold text-[14px] sm:text-[18px]">申辦類別: 
                      <span className="text-[14px] sm:text-[18px] font-normal pl-2">{translateProcessType(detail.process_type)}</span>
                    </span>
                    <span className="text-[14px] sm:text-[18px] font-normal">${detail.total_price}</span>
                  </div>
                </div>
              ))}
            </div>
            <hr className=" border-[#A3A3A3]" />
            <div className="flex justify-between w-full">
              <span className="font-bold  text-[14px] sm:text-[18px]">總計: </span>
              <span className=" text-[14px] sm:text-[18px] font-normal text-[#EE1D52]">${reportData?.amount}</span>
            </div>
          </div>
          <div className="w-full gap-y-[40px] grid justify-center">
          <div className="w-full text-center flex flex-col gap-y-4 justify-between items-center">
            <div className="font-bold text-[20px] sm:text-[24px]">付款方式&mdash;匯款</div>
            <div className=" text-[16px] flex text-start flex-col max-w-[367px] gap-y-2 text-base md:text-[18px]">
              <span>彰化銀行 忠孝東路分行</span>
              <div className="flex gap-x-2">
                <div className="bg-btn01 pl-2  rounded-[4px]">
                <span className="text-white">銀行代碼：</span>
                </div>
                <span>009</span>
              </div>
              <div className="flex gap-x-2">
                <div className="bg-btn01 pl-2  rounded-[4px]">
                <span className="text-white">分行代碼：</span>
                </div>
                <span>5203</span>
              </div>
              <div className="flex gap-x-2">
                <div className="bg-btn01 pl-2  rounded-[4px]">
                <span className="text-white">帳號：</span>
                </div>
                <span>520301-023265-00</span>
              </div>
              <div className="flex gap-x-2 ">
                <div className="bg-btn01 pl-2 rounded-[4px] h-[24px] flex items-center">
                  <span className="text-white whitespace-nowrap leading-none">戶名：</span>
                </div>
                <span className="break-all">紅牛國際旅行社有限公司 台北分公司</span>
              </div>

            </div>
            <div className=" text-[16px] text-base md:text-[18px]">
              請在<span className="text-[#EE1D52]">24小時</span>內完成付款，若超過期限將會取消訂單。
            </div>
          </div>
          <button
            className="font-bold text-btn01  text-[16px] sm:text-[18px] bg-white border border-btn01 w-full sm:w-[400px] md:w-[460px] 
            h-[44px] sm:h-[48px] md:h-[58px] rounded-[18px] sm:rounded-xl hover:bg-[#66C0DD] hover:text-white active:bg-[#2F99BA]"
            onClick={() => navigate(`/progress/status/report`, { state: { applicationId: application_id } })}
          >
            繼續完成匯款
          </button>
          </div>
        </>
      ) : (
        <div>Loading...</div>
      )}
      {/* 說明 */}
      {isModalOpen && (
        <div
          className="bg-black/70 fixed inset-0 flex justify-center items-center z-30"
          onClick={closeModal} // 點擊模態框外部來關閉
        >
          <div
            className="mx-4 gap-y-[16px] px-4 sm:px-[60px] py-[40px] relative bg-white z-10  flex flex-col rounded-[25px]"
            onClick={(e) => e.stopPropagation()} // 阻止點擊事件向上冒泡
          >
            <div className="w-10 h-10 cursor-pointer pt-5 pr-4 sm:pt-4 sm:pr-[14px] md:pr-[29px] absolute right-0 top-0"
             onClick={closeModal}
            >
            <button
              className="cross  w-6 h-6 sm:w-[24px] sm:h-[24px]  "
            ></button>
            </div>
            <div className=" grid gap-y-[16px]">
            <div className="flex ">
              <img src={one} alt="" className="w-[24px] sm:w-[40px]  h-[24px] sm:h-[40px]  mr-2 sm:mr-6 " />
              <div>
                <span className="text-[18px] sm:text-[32px]  font-bold">
                  選擇要辦理<span className="text-orange">護照</span>還是
                  <span className="text-orange">台胞證</span>
                </span>
                <br />
                <span className="text-[16px] sm:text-[20px] leading-[28px]">
                  護照加辦台胞證，幫家人一起申請也OK!
                </span>
              </div>
            </div>
            <div className="flex">
              <img src={two} alt="" className="w-[24px] sm:w-10 h-[24px] sm:h-10 mr-2 sm:mr-6 " />
              <div>
                <span className="text-[18px] sm:text-[32px] font-bold">
                  確認好內容後<span className="text-orange">上傳文件</span>送出申請
                </span>
              </div>
            </div>
            <div className="flex">
              <img src={three} alt="" className="w-[24px] sm:w-10 h-[24px] sm:h-10 mr-2 sm:mr-6 " />
              <div>
                <span className="text-[18px] sm:text-[32px] font-bold">
                  通過審核批准後可至<span className="text-orange">進度查詢</span>前往繳費
                </span>
                <br />
                <span className="text-[16px] sm:text-[20px] leading-[28px]">
                  繳費完成後可以選擇時間跟地點安排人員前往收取相關文件
                </span>
              </div>
            </div>
            <div className="flex">
              <img src={four} alt="" className="w-[24px] sm:w-10  h-[24px] sm:h-10  mr-2 sm:mr-6 " />
              <div>
                <span className="text-[18px] sm:text-[32px] font-bold">等待收件就完成囉 !</span>
                <br />
                <span className="text-[16px] sm:text-[20px] leading-[28px]">
                  證件辦理完成後填寫送件地址，可把證件送至府上 !
                </span>
              </div>
            </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProgressContinue;
