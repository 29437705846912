import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import apiBaseURL from "../../config";
import one from "../../image/one.svg";
import two from "../../image/two.svg";
import three from "../../image/three.svg";
import four from "../../image/four.svg";
import Loading from "../../components/Loading";

function ProgressChosePay({ isModalOpen, closeModal, setApplicationStatus }) {
  const navigate = useNavigate();
  const application_id =localStorage.getItem("application_id");
  const [reportData, setReportData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    const token = localStorage.getItem("access_token");

    const fetchPaymentInfo = async () => {
      try {
        const response = await fetch(
          `${apiBaseURL}/v1/traveler/applications/${application_id}/payment`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setReportData(data.data);
        } else {
          console.error("Failed to fetch payment info");
        }
      } catch (error) {
        console.error("Error fetching payment info:", error);
      }
    };

    fetchPaymentInfo();
  }, [application_id]);

  const handleOnlinePayment = async () => {
    setIsLoading(true);  
    const token = localStorage.getItem("access_token");
    try {
        const response = await fetch(`${apiBaseURL}/v1/traveler/applications/gen_id`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });

        if (response.ok) {
            const data = await response.json();
            const requestId = data.data.request_id;

            const paymentResponse = await fetch(
                `${apiBaseURL}/v1/traveler/applications/${application_id}/payment/checkout`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        order_id: requestId,
                    }),
                }
            );

            if (paymentResponse.ok) {
                const paymentData = await paymentResponse.json();
                const paymentUrl = paymentData.data.url;
                window.location.href = paymentUrl;
            } else {
                console.error("Failed to complete payment checkout");
            }
        } else {
            console.error("Failed to fetch request ID");
        }
    } catch (error) {
        console.error("Error during payment process:", error);
    } finally {
        setIsLoading(false);  // 隱藏 Loading 無論成功或失敗
    }
};

  const translateProcessType = (type) => {
    const translationMap = {
      REGULAR: "一般件",
      EXPRESS: "速件",
    };
    return translationMap[type] || type;
  };

  

  return (
      <div className=" min-w-[288px] max-w-[954px] w-full sm:px-8 px-4 flex flex-col mx-auto items-center  gap-y-[40px] mt-[144px] mb-[100px]">
      <div className="p-6 w-full flex flex-col gap-y-[24px] rounded-xl border shadow-xl">
        {reportData ? (
          <>
            <div className="w-full flex flex-col gap-y-[8px]">
              <span className="font-bold text-[14px] sm:text-base md:text-[18px]">
                申請時間:
                <span className="text-[14px] sm:text-base md:text-[18px] font-normal pl-2">
                  {reportData?.application_date}
                </span>
              </span>
              <span className="font-bold text-[14px] sm:text-base md:text-[18px]">
                申辦人:
                <span className="text-[14px] sm:text-base md:text-[18px] font-normal pl-2">
                  {reportData?.name}
                </span>
              </span>
              {reportData?.payment_details?.map((detail,index) => (
                 <div key={detail.payment_detail_id} className="flex flex-col gap-y-2">
                  {index !== 0 && <hr className="border-[#A3A3A3]" />}
                   <span className="font-bold text-[14px] sm:text-base md:text-[18px]">申辦證件: 
                     <span className="text-[14px] sm:text-base md:text-[18px] font-normal pl-2">{detail.name}</span>
                   </span>
                   <span className="font-bold text-[14px] sm:text-base md:text-[18px]">總件數: 
                     <span className="text-[14px] sm:text-base md:text-[18px] font-normal pl-2">{detail.quantity}</span>
                   </span>
                   <div className="flex justify-between w-full">
                     <span className="font-bold text-[14px] sm:text-base md:text-[18px]">申辦類別: 
                       <span className="text-[14px] sm:text-base md:text-[18px] font-normal pl-2">{translateProcessType(detail.process_type)}</span>
                     </span>
                     <span className="text-[14px] sm:text-base md:text-[18px] font-normal">${detail.total_price}</span>
                   </div>
                 </div>
               ))}
            </div>
            <hr className=" border-[#A3A3A3]" />
            <div className="flex justify-between w-full">
              <span className="font-bold text-[14px] sm:text-base md:text-[18px]">總計: </span>
              <span className="text-[14px] sm:text-base md:text-[18px] font-normal text-[#EE1D52]">
                ${reportData?.amount}
              </span>
            </div>
          </>
        ) : (
          <div>加載中...</div>
        )}
      </div>
      <div className="flex items-center justify-center flex-col w-full gap-y-[30px]">
        <div className="font-bold text-[20px] md:text-[24px]">請選擇付款方式</div>
        <div className="w-full flex flex-col justify-center items-center gap-y-[20px]">
          <button className="font-bold text-btn01 text-[14px] sm:text-base md:text-[18px] bg-white border border-btn01 w-full sm:w-[400px] md:w-[460px] h-[44px] sm:h-[48px] md:h-[58px] rounded-[16px] sm:rounded-xl hover:bg-[#66C0DD] hover:text-white active:bg-[#2F99BA]"
          onClick={handleOnlinePayment}
          >
            線上付款
          </button>
          <button
            className="font-bold text-btn01 text-[14px] sm:text-base md:text-[18px] bg-white border border-btn01 w-full sm:w-[400px] md:w-[460px]  h-[44px] sm:h-[48px] md:h-[58px] rounded-[16px] sm:rounded-xl hover:bg-[#66C0DD] hover:text-white active:bg-[#2F99BA]"
            onClick={() => navigate(`/progress/status/continue`, { state: { applicationId: application_id } })}
          >
            匯款
          </button>
          <button
            className="font-bold text-btn01 text-[14px] sm:text-base md:text-[18px] bg-white border border-btn01 w-full sm:w-[400px] md:w-[460px]  h-[44px] sm:h-[48px] md:h-[58px] rounded-[16px] sm:rounded-xl hover:bg-[#66C0DD] hover:text-white active:bg-[#2F99BA]"
            onClick={() => {
              setApplicationStatus("PAID", true);
              navigate(`/progress/status`, { state: { applicationId: application_id } });
            }}
          >
            線下付款
          </button>
        </div>
      </div>
      {isLoading && <Loading/>}
      {/* 說明 */}
      {isModalOpen && (
        <div
          className="bg-black/70 fixed inset-0 flex justify-center items-center z-30"
          onClick={closeModal} // 點擊模態框外部來關閉
        >
          <div
            className="mx-4 gap-y-[16px] px-4 sm:px-[60px] py-[40px] relative bg-white z-10  flex flex-col rounded-[25px]"
            onClick={(e) => e.stopPropagation()} // 阻止點擊事件向上冒泡
          >
            <div className="w-10 h-10 cursor-pointer pt-5 pr-4 sm:pt-4 sm:pr-[14px] md:pr-[29px] absolute right-0 top-0"
             onClick={closeModal}
            >
            <button
              className="cross  w-6 h-6 sm:w-[24px] sm:h-[24px]  "
            ></button>
            </div>
            <div className=" grid gap-y-[16px]">
            <div className="flex ">
              <img src={one} alt="" className="w-[24px] sm:w-[40px]  h-[24px] sm:h-[40px]  mr-2 sm:mr-6 " />
              <div>
                <span className="text-[18px] sm:text-[32px]  font-bold">
                  選擇要辦理<span className="text-orange">護照</span>還是
                  <span className="text-orange">台胞證</span>
                </span>
                <br />
                <span className="text-[16px] sm:text-[20px] leading-[28px]">
                  護照加辦台胞證，幫家人一起申請也OK!
                </span>
              </div>
            </div>
            <div className="flex">
              <img src={two} alt="" className="w-[24px] sm:w-10 h-[24px] sm:h-10 mr-2 sm:mr-6 " />
              <div>
                <span className="text-[18px] sm:text-[32px] font-bold">
                  確認好內容後<span className="text-orange">上傳文件</span>送出申請
                </span>
              </div>
            </div>
            <div className="flex">
              <img src={three} alt="" className="w-[24px] sm:w-10 h-[24px] sm:h-10 mr-2 sm:mr-6 " />
              <div>
                <span className="text-[18px] sm:text-[32px] font-bold">
                  通過審核批准後可至<span className="text-orange">進度查詢</span>前往繳費
                </span>
                <br />
                <span className="text-[16px] sm:text-[20px] leading-[28px]">
                  繳費完成後可以選擇時間跟地點安排人員前往收取相關文件
                </span>
              </div>
            </div>
            <div className="flex">
              <img src={four} alt="" className="w-[24px] sm:w-10  h-[24px] sm:h-10  mr-2 sm:mr-6 " />
              <div>
                <span className="text-[18px] sm:text-[32px] font-bold">等待收件就完成囉 !</span>
                <br />
                <span className="text-[16px] sm:text-[20px] leading-[28px]">
                  證件辦理完成後填寫送件地址，可把證件送至府上 !
                </span>
              </div>
            </div>
            </div>
          </div>
        </div>
      )}
      </div>
  );
}

export default ProgressChosePay;
