import React, { useState, useEffect } from "react";
import first from "../../image/first.svg";
import second from "../../image/second.svg";
import datepicker from "../../image/datepicker.svg";
import error from "../../image/error.svg";
import Dropdown from "./Dropdown"; 
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import zhTW from "date-fns/locale/zh-TW";
import apiBaseURL from "../../config";
import axios from "axios";
import Loading from "../Loading";

function Paid( { status , isOfflinePayment,setApplicationStatus,setIsOfflinePayment,application_id } ) {
  const [selectedCounty, setSelectedCounty] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [selectedButton, setSelectedButton] = useState(null);
  const [cityOptions, setCityOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [pickupAddress, setPickupAddress] = useState(""); 
  const [pickupPhone, setPickupPhone] = useState(""); 
  const [lastPickupInfo, setLastPickupInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);


  const timeOptions = ["08:00-12:00", "12:00-16:00", "16:00-20:00"];

  // 自動回到頂部
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  // 取得縣市
  useEffect(() => {
    const fetchCities = async () => {
      const token = localStorage.getItem("access_token"); // 從 localStorage 中取得 token
      try {
        const response = await axios.get(`${apiBaseURL}/v1/cities`, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        });
        const cities = response.data.data.map((city) => ({
          id: city.city_id,
          name: city.city_name,
        }));
        setCityOptions(cities);
      } catch (error) {
        console.error("Failed to fetch cities", error);
      }
    };

    fetchCities();
  }, []);
  // 取得區
  const fetchDistricts = async (city_id) => {
    const token = localStorage.getItem("access_token");
    try {
      const response = await axios.get(
        `${apiBaseURL}/v1/cities/${city_id}/districts`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      );
      const districts = response.data.data.map((district) => ({
        id: district.district_id,
        name: district.district_name,
      }));
      setDistrictOptions(districts);
    } catch (error) {
      console.error("Failed to fetch districts", error);
    }
  };

  useEffect(() => {
    if (status === "WAITING_FOR_ADDITIONAL_DOCUMENT") {
      const fetchLastPickupInfo = async () => {
        const token = localStorage.getItem("access_token");
        try {
          const response = await axios.get(
            `${apiBaseURL}/v1/traveler/applications/${application_id}/pickup_info`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
              },
            }
          );
          setLastPickupInfo(response.data.data);
        } catch (error) {
          console.error("Failed to fetch last pickup info", error);
        }
      };
  
      fetchLastPickupInfo();
    }
  }, [status, application_id]);

  useEffect(() => {
    if (selectedButton === "last" && lastPickupInfo) {
      setSelectedCounty(lastPickupInfo.city);
      setSelectedDistrict(lastPickupInfo.district);
      setPickupAddress(lastPickupInfo.pickup_address);
    }
  }, [selectedButton, lastPickupInfo]);

  const handleSubmit = async () => {
    // 檢查所有必填欄位
    if (
      !selectedCounty ||
      !selectedDistrict ||
      !pickupAddress ||
      !selectedDate ||
      !pickupPhone ||
      !selectedTime
    ) {
      alert("請完整填寫所有資料");
      return; // 阻止提交
    }
  
    const token = localStorage.getItem('access_token');
    const pickupDate = selectedDate.toLocaleDateString("zh-TW", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).replace(/\//g, '-');
    
    const payload = {
      city: selectedCounty || null,
      district: selectedDistrict || null, 
      pickup_address: pickupAddress || null,
      pickup_date: pickupDate,
      pickup_phone: pickupPhone || null,
      pickup_time: selectedTime || null,
      is_offline_payment: isOfflinePayment,
    };
  
    console.log(payload.pickup_date);
  
    try {
      setIsLoading(true);
      let response;
  
      // 根據狀態決定使用 POST 還是 PUT
      if (status === "WAITING_FOR_ADDITIONAL_DOCUMENT") {
        response = await axios.put(
          `${apiBaseURL}/v1/traveler/applications/${application_id}/pickup_info`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
            },
          }
        );
      } else {
        response = await axios.post(
          `${apiBaseURL}/v1/traveler/applications/${application_id}/pickup_info`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
            },
          }
        );
      }
  
      if (response.status === 200) {
        // 成功提交後更新狀態
        setApplicationStatus("READY_FOR_PICKUP");
        setIsOfflinePayment(isOfflinePayment);
      }
      console.log("Success:", response.data);
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setIsLoading(false);
    }
  };
  
  
  
  useEffect(() => {
    if (isOfflinePayment) {
      console.log("線下付款處理");
    }
  }, [isOfflinePayment]);

  return (
    <div className="w-full mb-[100px]">
      {isLoading && <Loading/>}
      <div className="bg-white px-[16px] py-[24px] flex flex-col gap-y-[40px] rounded-xl shadow-xl mb-[22px]">
      {status === "WAITING_FOR_ADDITIONAL_DOCUMENT" && (
        <div className="flex flex-col gap-y-[40px]">
          <span className="text-center font-bold text-[20px] md:text-[24px]">請再次選擇取件時間</span>
          <div className="flex flex-col gap-y-[16px] items-center">
            <img src={error} alt=""  className="w-[40px] h-[40px]"/>
            <div className="flex-col flex items-center">
              <span className="font-bold text-base sm:text-[18px]">尚有未繳交文件</span>
              <span className="text-[14px] text-[#545454]">請備齊補件資料，再次選擇取件時間。</span>
            </div>
          </div>
        </div>
      )}
        <div className="flex flex-col gap-y-[24px]">
          <div className="flex">
            <img src={first} alt="" />
            <span className="font-bold ml-3 text-[20px] md:text-[24px]">取件區域</span>
          </div>
          {status === "WAITING_FOR_ADDITIONAL_DOCUMENT" && (
            <div className="flex flex-col gap-y-4 gap-x-[24px] sm:flex-row">
              <button
                className={` w-full sm:w-[220px] h-[40px] text-base sm:text-[18px] rounded-xl ${selectedButton === "last" ? "bg-orange text-white" : "bg-[#DEDEDE] text-[#545454]"}`}
                onClick={() => setSelectedButton("last")}
              >
                同上次
              </button>
              <button
                className={` w-full sm:w-[220px] h-[40px] text-base sm:text-[18px] rounded-xl ${selectedButton === "change" ? "bg-orange text-white" : "bg-[#DEDEDE] text-[#545454]"}`}
                onClick={() => setSelectedButton("change")}
              >
                改地址
              </button>
            </div>
          )}
          {(selectedButton === "change" || status !== "WAITING_FOR_ADDITIONAL_DOCUMENT") && (
            <>
              <div className="grid sm:grid-cols-2 gap-y-6 sm:gap-x-[32px]">
                <Dropdown
                  label="縣市"
                  options={cityOptions.map((city) => city.name)}
                  selectedOption={selectedCounty}
                  onOptionSelect={(county) => {
                    const selectedCity = cityOptions.find(
                      (city) => city.name === county
                    );
                    setSelectedCounty(county);
                    setSelectedDistrict(""); // 重置行政區選擇
                    fetchDistricts(selectedCity.id); // 根據選取的縣市 ID 獲取行政區
                  }}
                  placeholder="縣市"
                />
                <Dropdown
                  label="行政區"
                  options={districtOptions.map((district) => district.name)}
                  selectedOption={selectedDistrict}
                  onOptionSelect={setSelectedDistrict}
                  placeholder="行政區"
                />
              </div>
              <div>
                <div className="text-base md:text-[18px] font-bold mb-[16px]">地址</div>
                <input 
                  type="text" 
                  className="w-full rounded-md bg-[#F7F7F9] border text-base md:text-[18px] Input px-[20px] py-[14px]" 
                  placeholder="地址"
                  onChange={(e) => setPickupAddress(e.target.value)}
                />
              </div>
            </>
          )}
          <div>
            <div className="text-base md:text-[18px] font-bold mb-[16px]">連絡電話</div>
            <input 
              type="text" 
              className="w-full rounded-md bg-[#F7F7F9] text-base md:text-[18px] Input px-[20px] py-[14px] " 
              placeholder="請輸入電話號碼"
              onChange={(e) => setPickupPhone(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-col gap-y-[24px]">
          <div className="flex">
            <img src={second} alt="" />
            <span className="font-bold ml-3 text-[20px] md:text-[24px]">取件時間</span>
          </div>
          <div className="grid gap-y-[24px] sm:grid-cols-2 gap-x-[32px]">
            <div className="flex flex-col">
              <span className="text-base md:text-[18px] font-bold mb-4">日期</span>
              <div className="relative">
                <input 
                  type="text" 
                  className="w-full rounded-md bg-[#F7F7F9] text-base md:text-[18px] Input px-[20px] py-[14px]" 
                  placeholder={new Date().toISOString().split('T')[0].replace(/-/g, '/')} 
                  value={selectedDate ? selectedDate.toLocaleDateString() : ""}
                  readOnly
                  onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
                />
                <div
                  className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
                >
                  <img src={datepicker} alt=""/>
                </div>
                {isDatePickerOpen && (
                  <div className="absolute z-50 w-full flex justify-end mt-2 right-0 ">
                    <DatePicker
                      selected={selectedDate}
                      onChange={(date) => {
                        setSelectedDate(date);
                        setIsDatePickerOpen(false);
                      }}
                      inline
                      minDate={new Date(new Date().setDate(new Date().getDate() + 2))}
                      filterDate={(date) => date.getDay() !== 0 && date.getDay() !== 6}
                      locale={zhTW}
                      className="react-datepicker"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col">
               <Dropdown
                 label="時間"
                 options={timeOptions}
                 selectedOption={selectedTime}
                 onOptionSelect={setSelectedTime}
                 placeholder="08:00-12:00"
                 icon={datepicker} 
               />
              </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <button className="h-[58px] w-[460px] rounded-[28px] bg-btn01 text-white font-bold"
        onClick={handleSubmit}
        >
        提交
        </button>
      </div>
    </div>
  );
}

export default Paid;
