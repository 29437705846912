import React, { useEffect, useState } from "react";
import apiBaseURL from "../../config";

function WaitingDelivery({application_id}) {
  const [qrCode, setQrCode] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('access_token');

    const fetchQrCode = async () => {
      try {
        const qrCodeResponse = await fetch(`${apiBaseURL}/v1/traveler/applications/${application_id}/qr_code`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        if (qrCodeResponse.ok) {
          const qrCodeData = await qrCodeResponse.json();
          setQrCode(qrCodeData.data.qr_code); // 假設後端返回的QR Code 是Base64格式的圖片
        } else {
          console.error("Failed to fetch QR code");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchQrCode();
  }, [application_id]);

  return (
    <div className="flex flex-col h-[362px] justify-between items-center">
      <div className="flex flex-col items-center">
        <span className="font-bold text-[20px] sm:text-[24px] mb-[50px]">等待送件中</span>
        <span className="text-[14px] text-center sm:text-base md:text-[18px] mb-[40px]">當取件人員前往收取證件申辦資料時，可出示此QR code或提供電話號碼，加速取件人員讀取訂單。</span>
      </div>
      <div className="w-[279px] h-[308px] flex justify-center items-center text-white">
        {qrCode ? (
          <img src={qrCode} alt="QR Code" />
        ) : (
          <span>加載中...</span>
        )}
      </div>
    </div>
  );
}

export default WaitingDelivery;
