import React from "react";
import { useNavigate } from "react-router-dom";
import { useAutoLogout } from "../hook/useApi";

function AutoLogoutWrapper({ children }) {
  const navigate = useNavigate();

  const handleLogout = () => {
    window.alert("登入時間過長，請重新登入。");
    // 清除認證信息
    localStorage.removeItem("access_token");
    localStorage.removeItem("role");
    localStorage.removeItem("login_time");
    // 導向登入頁面
    navigate("/");
  };

  useAutoLogout(handleLogout);

  return <>{children}</>;
}

export default AutoLogoutWrapper;
