import React from "react";
import Hint from "../Hint";

const PassportDetail = ({
  selectFirst,
  englishAliasOptions,
  selectEnglish,
  handleEnglishClick,
  changeEnglishName,
  changeEnglish,
  handleChangeEnglishClick,
  changeAlias,
  changeEnglishAlias,
  handleAliasClick,
  checkedStates,
  files = [],
  handleClick,
  fileInputRef,
  handleFileChange,
  showHint,
  hintTitle,
  hintMessage,
  setShowHint,
  setFiles,
  idcard,
  close,
  upload,
  second,
  setForms,
  index,
  forms
}) => {
  const handleCheckboxChange = (name) => {
    setForms((prevForms) =>
      prevForms.map((form, i) =>
        i === index
          ? {
              ...form,
              checkedStates: {
                ...form.checkedStates,
                [name]: !form.checkedStates[name],
              },
            }
          : form
      )
    );
  };

  // 更新 handleFileDelete 以確保只刪除特定文件
  const handleFileDelete = (fileIndex) => {
    setForms((prevForms) =>
      prevForms.map((form, i) =>
        i === index
          ? {
              ...form,
              files: form.files.filter((_, j) => j !== fileIndex),
            }
          : form
      )
    );
  };

  return (
    <>
      {selectFirst === "FIRST_TIME" && (
        <div className="grid gap-y-[16px]">
          <div className="grid sm:grid-cols-2 gap-x-[32px] gap-y-[24px] w-full">
            {/* 外文姓氏 */}
            <div className="grid gap-y-[16px]">
              <span className="font-bold text-base sm:text-[18px]">外文姓氏</span>
              <input
                type="text"
                placeholder="請輸入姓氏，範例 : WANG"
                value={forms[index].foreignLastName}
                onChange={(e) =>
                  setForms((prevForms) =>
                    prevForms.map((form, i) =>
                      i === index ? { ...form, foreignLastName: e.target.value } : form
                    )
                  )
                }
                className="border rounded-md bg-[#F7F7F9] Input text-sm sm:text-base p-[14px] h-[53px]"
              />
            </div>
            {/* 外文名字 */}
            <div className="grid gap-y-[16px]">
              <span className="font-bold text-base sm:text-[18px]">外文名字</span>
              <input
                type="text"
                placeholder="請輸入名字，範例 : TSE-SHIH"
                value={forms[index].foreignFirstName}
                onChange={(e) =>
                  setForms((prevForms) =>
                    prevForms.map((form, i) =>
                      i === index ? { ...form, foreignFirstName: e.target.value } : form
                    )
                  )
                }
                className="border rounded-md text-sm sm:text-base bg-[#F7F7F9] p-[14px] Input h-[53px]"
              />
            </div>
          </div>
          <ul className="list-disc pl-6 text-base">
            <li >
              請參考
              <a
                href="https://www.boca.gov.tw/sp-natr-singleform-1.html"
                target="_blank"
                rel="noreferrer"
                className="underline text-[#EE1D52] "
              >
                外文姓名譯音系統
              </a>
              ，限輸入英文字母及符號「-」、「．」，建議外文姓氏與直系血親或兄弟姐妹拼法相同。
            </li>
            <li>如果變更中文或外文姓名，原外文姓名應列為外文別名。</li>
            <li>變更外文姓名或拼音如非中文姓名國家語言讀音音譯，須提供證明文件。</li>
            <li>
              臺灣原住民、其他少數民族及歸化我國國籍者，得以姓名並列的羅馬拼音作為外文姓名，不區分姓名者，請將外文姓名直接輸入「姓氏」欄位。
            </li>
            <li>
              外文姓名含字間在內，不得超過37個英文字母，超過須以縮寫表示。（按護照條例施行細則第14條第2項第6款規定「外文姓名之排列方式，姓在前、名在後，且含字間在內，不得超過39個字母」，其中扣除區分姓氏與名字之逗號與字間，總計可自行輸入37個英文字母）
            </li>
          </ul>
          <div className="grid gap-y-[16px]">
            <span className="font-bold text-base sm:text-[18px]">外文別名(無擇免填)</span>
            <div className=" grid-cols-2 sm:grid-cols-4 grid gap-x-[10px]">
              {englishAliasOptions.map((option) => (
                <button
                  key={option.value}
                  className={`w-full h-[40px] text-[16px]  rounded-lg text-center ${
                    selectEnglish === option.value ? "bg-orange text-white" : "bg-[#DEDEDE]"
                  } text-[#545454]`}
                  onClick={() => handleEnglishClick(option.value)}
                >
                  {option.label}
                </button>
              ))}
            </div>
            {/* 如果有外文別名 */}
            {selectEnglish === "have" && (
              <div>
                <input
                  type="text"
                  placeholder="請輸入外文別名"
                  value={forms[index].alias}
                  onChange={(e) =>
                    setForms((prevForms) =>
                      prevForms.map((form, i) =>
                        i === index ? { ...form, alias: e.target.value } : form
                      )
                    )
                  }
                  className="border rounded-md bg-[#F7F7F9] w-full Input sm:w-[312px] md:w-[445px] p-[14px]  h-[53px] mb-4 "
                />
                <ul className="list-disc pl-6 text-base">
                  <li>限輸入英文字母及符號「,」、「-」、「．」。</li>
                  <li>
                    外文別名應有姓氏，並應與外文姓名的姓氏一致，或依申請人對其中文姓氏的國家語言讀音音譯為英文字母。如果有國內外政府核發的外文身分證明文件或正式文件、國內外醫院核發的出生證明、或經教育主管機關正式立案的公、私立學校製發的證明文件，得優先採用。繳附的文件在國外製作，其外文本及中譯本均應經駐外館處驗證。
                  </li>
                  <li>
                    外文別名含字間在內，不得超過39個英文字母，超過須以縮寫表示。
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      )}

      {(selectFirst === "RENEWAL" || selectFirst === "REPLACEMENT") && (
        <div className="grid gap-y-[16px]">
          <span className="font-bold text-base sm:text-[18px]">外文姓名</span>
          <div className=" grid grid-cols-2 sm:grid-cols-4 gap-x-[10px]">
            {changeEnglishName.map((option) => (
              <button
                key={option.value}
                className={` h-[40px] text-[16px] rounded-lg text-center ${
                  changeEnglish === option.value ? "bg-orange text-white" : "bg-[#DEDEDE]"
                } text-[#545454]`}
                onClick={() => handleChangeEnglishClick(option.value)}
              >
                {option.label}
              </button>
            ))}
          </div>
          {/* 變更外文姓名 */}
          {changeEnglish === "change" && (
            <>
              <div className="grid sm:grid-cols-2 gap-x-[32px] gap-y-[24px] w-full">
                {/* 外文姓氏 */}
                <div className="grid gap-y-[16px]">
                  <span className="font-bold text-base sm:text-[18px]">外文姓氏</span>
                  <input
                    type="text"
                    placeholder="請輸入姓氏，範例 : WANG"
                    value={forms[index].foreignLastName}
                    onChange={(e) =>
                      setForms((prevForms) =>
                        prevForms.map((form, i) =>
                          i === index ? { ...form, foreignLastName: e.target.value } : form
                        )
                      )
                    }
                    className="border rounded-md Input bg-[#F7F7F9] p-[14px] h-[53px]"
                  />
                </div>
                {/* 外文名字 */}
                <div className="grid gap-y-[16px]">
                  <span className="font-bold  text-base sm:text-[18px]">外文名字</span>
                  <input
                    type="text"
                    placeholder="請輸入名字，範例 : TSE-SHIH"
                    value={forms[index].foreignFirstName}
                    onChange={(e) =>
                      setForms((prevForms) =>
                        prevForms.map((form, i) =>
                          i === index ? { ...form, foreignFirstName: e.target.value } : form
                        )
                      )
                    }
                    className="border rounded-md bg-[#F7F7F9] Input p-[14px] h-[53px]"
                  />
                </div>
              </div>
              <ul className="list-disc pl-6">
                <li>
                  請參考
                  <a
                    href="https://www.boca.gov.tw/sp-natr-singleform-1.html"
                    target="_blank"
                    rel="noreferrer"
                    className="underline text-[#EE1D52]"
                  >
                    外文姓名譯音系統
                  </a>
                  ，限輸入英文字母及符號「-」、「．」，建議外文姓氏與直系血親或兄弟姐妹拼法相同。
                </li>
                <li>如果變更中文或外文姓名，原外文姓名應列為外文別名。</li>
                <li>變更外文姓名或拼音如非中文姓名國家語言讀音音譯，須提供證明文件。</li>
                <li>
                  臺灣原住民、其他少數民族及歸化我國國籍者，得以姓名並列的羅馬拼音作為外文姓名，不區分姓名者，請將外文姓名直接輸入「姓氏」欄位。
                </li>
                <li>
                  外文姓名含字間在內，不得超過37個英文字母，超過須以縮寫表示。（按護照條例施行細則第14條第2項第6款規定「外文姓名之排列方式，姓在前、名在後，且含字間在內，不得超過39個字母」，其中扣除區分姓氏與名字之逗號與字間，總計可自行輸入37個英文字母）
                </li>
              </ul>
            </>
          )}
        </div>
      )}

      {/* 變更外文別名按鈕 */}
      {(selectFirst === "RENEWAL" || selectFirst === "REPLACEMENT") && (
        <div className="grid  gap-y-[16px]">
          <span className="font-bold  text-base sm:text-[18px]">外文別名</span>
          <div className="grid w-full sm:grid-cols-4 grid-rows-4 sm:grid-rows-none sm:auto-cols-max gap-x-[10px] gap-y-[10px]">
            {changeEnglishAlias.map((option) => (
              <button
                key={option.value}
                className={` h-[40px] text-[16px]  rounded-lg text-center ${
                  changeAlias === option.value ? "bg-orange text-white" : "bg-[#DEDEDE]"
                } text-[#545454]`}
                onClick={() => handleAliasClick(option.value)}
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
      )}

      {/* 外文別名內容顯示 */}
      {(selectFirst === "RENEWAL" || selectFirst === "REPLACEMENT") &&
        changeAlias === "addAlias" && (
          <div className="grid gap-y-[16px]">
            <div className="font-bold  text-base sm:text-[18px]">新增外文別名</div>
            <input
              type="text"
              placeholder="請輸入姓氏，範例 : WANG"
              value={forms[index].alias}
              onChange={(e) =>
                setForms((prevForms) =>
                  prevForms.map((form, i) =>
                    i === index ? { ...form, alias: e.target.value } : form
                  )
                )
              }
              className="border rounded-md bg-[#F7F7F9] sm:w-[50%] w-full Input p-[14px] h-[53px] mb-4"
            />
            <ul className="list-disc pl-6 text-base">
              <li>限輸入英文字母及符號「,」、「-」、「．」。</li>
              <li>
                外文別名應有姓氏，並應與外文姓名的姓氏一致，或依申請人對其中文姓氏的國家語言讀音音譯為英文字母。如果有國內外政府核發的外文身分證明文件或正式文件、國內外醫院核發的出生證明、或經教育主管機關正式立案的公、私立學校製發的證明文件，得優先採用。繳附的文件在國外製作，其外文本及中譯本均應經駐外館處驗證。
              </li>
              <li>
                外文別名含字間在內，不得超過39個英文字母，超過須以縮寫表示。
              </li>
            </ul>
          </div>
        )}
      {(selectFirst === "RENEWAL" || selectFirst === "REPLACEMENT") &&
        changeAlias === "removeAlias" && (
          <div className="grid gap-y-[16px]">
            <ul className="list-disc pl-6 text-base">
              <li>下次換發護照如擬增列外文別名須附證明文件。</li>
            </ul>
          </div>
        )}
      {(selectFirst === "RENEWAL" || selectFirst === "REPLACEMENT") &&
        changeAlias === "modifyAlias" && (
          <div className="grid gap-y-[16px]">
            <div className="font-bold  text-base sm:text-[18px]">變更外文別名</div>
            <input
              type="text"
              placeholder="請輸入姓氏，範例 : WANG"
              value={forms[index].alias}
              onChange={(e) =>
                setForms((prevForms) =>
                  prevForms.map((form, i) =>
                    i === index ? { ...form, alias: e.target.value } : form
                  )
                )
              }
              className="border rounded-md bg-[#F7F7F9] sm:w-[50%] w-full Input p-[14px] h-[53px] mb-4"
            />
            <ul className="list-disc pl-6 text-base">
              <li>限輸入英文字母及符號「,」、「-」、「．」。</li>
              <li>
                外文別名應有姓氏，並應與外文姓名的姓氏一致，或依申請人對其中文姓氏的國家語言讀音音譯為英文字母。如果有國內外政府核發的外文身分證明文件或正式文件、國內外醫院核發的出生證明、或經教育主管機關正式立案的公、私立學校製發的證明文件，得優先採用。繳附的文件在國外製作，其外文本及中譯本均應經駐外館處驗證。
              </li>
              <li>
                外文別名含字間在內，不得超過39個英文字母，超過須以縮寫表示。
              </li>
            </ul>
          </div>
        )}

      {/* 附件資料和上傳文件部分只在選擇護照類別後顯示 */}
      {selectFirst && (
        <>
          {/* 附件資料 */}
          <div className="grid gap-y-[16px]">
            <span className="font-bold text-base sm:-[18px]">請備齊勾選的應繳附件資料</span>
            <div className="grid gap-y-2">
              <div className="m-y-[16px] text-base sm:text-[18px]">
                應備文件
                <ul className="list-disc pl-8">
                  <li>護照用照片2張</li>
                  <li>身分證</li>
                  <li>
                    未成年者須附3個月內戶籍謄本（戶口名簿）及監護人其中一人的身分證
                  </li>
                  <li>
                    如辦理「加簽外文別名」 ：附證明文件，
                    如美國護照、綠卡、或駕照等使用該名之證件。
                  </li>
                  <li>
                    如辦理「修正外文別名」 ：附證明文件（持照人護照內倘曾作外文姓名之修正或增列，擬再次申請，則必須換領新照；如欲加冠英文夫姓，請提供結婚證書或相關文件如美國護照等
                    ）。
                  </li>
                </ul>
              </div>
              <div className="grid gap-y-2 text-base sm:text-[18px]">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    className="w-[24px] h-[24px] mr-[10px] custom-checkbox"
                    id="checkbox1"
                    checked={checkedStates.need_two_inch_photo}
                    readOnly
                  />
                  <label htmlFor="checkbox1">2吋照片</label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    className="w-[24px] h-[24px] mr-[10px] custom-checkbox"
                    id="checkbox2"
                    checked={checkedStates.need_ID}
                    readOnly
                  />
                  <label htmlFor="checkbox2">身分證</label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    className="w-[24px] h-[24px] mr-[10px] custom-checkbox"
                    id="checkbox3"
                    checked={checkedStates.need_old_passport}
                    readOnly
                  />
                  <label htmlFor="checkbox3">舊護照</label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    className="w-[24px] h-[24px] mr-[10px] custom-checkbox"
                    id="checkbox4"
                    checked={checkedStates.need_household_registry}
                    readOnly
                  />
                  <label htmlFor="checkbox4">戶口名簿／3個月內戶籍謄本</label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    className="w-[24px] h-[24px] mr-[10px] custom-checkbox"
                    id="checkbox5"
                    checked={checkedStates.need_legal_representative}
                    readOnly
                  />
                  <label htmlFor="checkbox5">法定代理人身分證</label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    className="w-[24px] h-[24px] mr-[10px] custom-checkbox"
                    id="checkbox7"
                    checked={checkedStates.need_lost_report}
                    readOnly
                  />
                  <label htmlFor="checkbox7">遺失申報表／入國證明書及入國登記</label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    className="w-[24px] h-[24px] mr-[10px] custom-checkbox"
                    id="checkbox8"
                    checked={checkedStates.need_alias_proof_document}
                    readOnly
                  />
                  <label htmlFor="checkbox8">外文姓名／別名的證明文件</label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    className="w-[24px] h-[24px] mr-[10px] custom-checkbox"
                    id="checkbox6"
                    checked={checkedStates.need_resident_certificate}
                    onChange={() => handleCheckboxChange("need_resident_certificate")}
                  />
                  <label htmlFor="checkbox6">非台灣出身者需附的文件證明</label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    className="w-[24px] h-[24px] mr-[10px] custom-checkbox"
                    id="checkbox9"
                    checked={checkedStates.need_other_document}
                    onChange={() => handleCheckboxChange("need_other_document")}
                  />
                  <label htmlFor="checkbox9">其他證件</label>
                </div>
              </div>

            </div>
          </div>
          {/* 上傳文件 */}
          <div>
            <div className="flex items-center mb-2">
              <img src={second} alt="" />
              <span className="ml-[12px] text-base sm:text-[20px] sm:text-[24px] font-bold">上傳文件</span>
            </div>
            <div className="h-[75px]">
              <span className="text-base sm:text-[18px] ">
                請上傳
                <span className="font-bold">
                  旅客的護照、身分證、相片、戶籍謄本、戶口名簿、舊證、監護人身分證、其他證件
                </span>
                <br />
                請確認上傳的檔案是否為以下格式：.jpeg、.png、.HEIF、.HEIC、.gif
              </span>
            </div>
            <div className="flex justify-center mt-[24px]">
              <div
                className={`h-[306px] w-full bg-[#F7F7F9] border-2 border-[#dedede] rounded-lg border-dotted flex flex-col justify-center items-center cursor-pointer`}
                onClick={handleClick}
              >
                <img
                  alt="upload"
                  src={upload}
                  className="h-8 sm:h-12 w-12 mb-2"
                />
                <div className="flex pb-2">
                  <p className=" text-2xl hidden sm:flex">檔案上傳</p>
                </div>
              </div>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </div>
          </div>
          {showHint && (
            <Hint
              title={hintTitle}
              message={hintMessage}
              onClose={() => setShowHint(false)}
            />
          )}
          <div className="grid sm:grid-cols-3 gap-x-4 gap-y-6">
            {Array.isArray(files) &&
              files.map((file, fileIndex) => (
                <div
                  key={fileIndex}
                  className="relative bg-[#F7F7F9] rounded-xl h-[86px] p-4 flex items-center"
                >
                  <div className="mr-4">
                    <img src={idcard} alt="" className="w-[40px] h-[27px]" />
                  </div>
                  <div className="max-w-[65%] break-words ">
                  <p className="text-[14px] sm:text-base font-bold">
                  {file.name.length > 10 ? `${file.name.slice(0, 10)}...${file.name.slice(file.name.lastIndexOf('.'))}` : file.name}
                  </p>
                    <p className="text-[14px] sm:text-base text-[#A3A3A3]">
                      {(file.size / 1024 / 1024).toFixed(2)} MB
                    </p>
                  </div>
                  <img
                    src={close}
                    alt="刪除圖標"
                    className="absolute top-4 right-4  cursor-pointer w-[18px] h-[18px]"
                    onClick={() => handleFileDelete(fileIndex)}
                  />
                </div>
              ))}
          </div>
        </>
      )}
    </>
  );
};

export default PassportDetail;
